exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-security-privacy-js": () => import("./../../../src/pages/data-security-privacy.js" /* webpackChunkName: "component---src-pages-data-security-privacy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-for-google-analytics-index-js": () => import("./../../../src/pages/integrations-for-google-analytics/index.js" /* webpackChunkName: "component---src-pages-integrations-for-google-analytics-index-js" */),
  "component---src-pages-partners-atlassian-index-js": () => import("./../../../src/pages/partners/atlassian/index.js" /* webpackChunkName: "component---src-pages-partners-atlassian-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-partners-monday-index-js": () => import("./../../../src/pages/partners/monday/index.js" /* webpackChunkName: "component---src-pages-partners-monday-index-js" */),
  "component---src-pages-policies-index-js": () => import("./../../../src/pages/policies/index.js" /* webpackChunkName: "component---src-pages-policies-index-js" */),
  "component---src-pages-policies-mdx-slug-js": () => import("./../../../src/pages/policies/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-policies-mdx-slug-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-mdx-slug-docs-js": () => import("./../../../src/pages/products/{mdx.slug}/docs.js" /* webpackChunkName: "component---src-pages-products-mdx-slug-docs-js" */),
  "component---src-pages-products-mdx-slug-iframed-js": () => import("./../../../src/pages/products/{mdx.slug}/iframed.js" /* webpackChunkName: "component---src-pages-products-mdx-slug-iframed-js" */),
  "component---src-pages-products-mdx-slug-index-js": () => import("./../../../src/pages/products/{mdx.slug}/index.js" /* webpackChunkName: "component---src-pages-products-mdx-slug-index-js" */),
  "component---src-pages-products-mdx-slug-pricing-js": () => import("./../../../src/pages/products/{mdx.slug}/pricing.js" /* webpackChunkName: "component---src-pages-products-mdx-slug-pricing-js" */),
  "component---src-pages-products-mdx-slug-title-js": () => import("./../../../src/pages/products/{mdx.slug}/title.js" /* webpackChunkName: "component---src-pages-products-mdx-slug-title-js" */)
}

