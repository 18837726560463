module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/david/src/dvdsmpsn/david-simpson-apps-website-gatsby","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_GB","url":"https://www.dsapps.dev","site_name":"David Simpson Apps","images":[{"url":"https://www.dsapps.dev/images/website-preview-800x400.jpg","width":800,"height":400}]},"twitter":{"handle":"@_dsapps","site":"@_dsapps","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.dsapps.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GLBW9Y6R95"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"cookie_flags":"SameSite=None;Secure;Partitioned"},"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
