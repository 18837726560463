import * as React from 'react';

const EmbedYouTube = ({ id, className = '', autoplay = '1' }) => (
  <div className={`embed-responsively feature-video feature-left ${className}`}>
    <iframe
      title="YouTube video player"
      src={`https://www.youtube-nocookie.com/embed/${
        id.indexOf('?') > 0 ? `${id}&` : `${id}?`
      }${
        autoplay === '1' ? 'autoplay=' : ''
      }&loop=1&modestbranding=1&mute=1&showinfo=0controls=0&rel=0`}
      frameBorder="0"
      allowFullScreen
    />
  </div>
);
export default EmbedYouTube;
